import {SelectOptionT} from '../types/helpers';
import {
  CodeCreateFields,
  CodeCreateFormValues,
  CodeGroupGuestT,
  codeItemT,
  CodeSendStatuses,
  CodeStatusTypes,
  GroupDetailTableDataT,
  UsedCodesT,
} from '../types/codes';
import {
  codeGroupBody,
  CodeGroupT,
  getListCodeGroupsRes,
  getListPromoCodesRes,
  PromoCodeT,
  SellerT,
} from '../queries/types/codes';
import {DashboardTableDataT} from '../types/dashboard';
import {removeEmptyKeys} from '../hooks/common';
import {emailState} from '../components/CodeGroup/View/Table';
import {CreatingGroupType} from '../states/group';

export const getTableCodesFilterOptions = (): SelectOptionT[] => {
  return [
    {label: 'Show All', value: CodeStatusTypes.all},
    {label: 'Codes Sent', value: CodeStatusTypes.sent},
    {label: 'Codes Pending', value: CodeStatusTypes.pending},
    // {label: 'Codes Received', value: CodeStatusTypes.received},
    // {label: 'Codes Used', value: CodeStatusTypes.used},
  ];
};

export const toCreateCodeData = (values: CodeCreateFormValues): codeGroupBody => {
  return {
    [CodeCreateFields.brand]: values?.brand || '',
    [CodeCreateFields.clientName]: values?.clientName || '',
    [CodeCreateFields.contactPerson]: values?.contactPerson || '',
    [CodeCreateFields.clientEmail]: values?.clientEmailList || '',
    [CodeCreateFields.groupType]: values?.groupType || '',
    [CodeCreateFields.department]: values?.department || '',
    [CodeCreateFields.promoCodes]: values?.promoCodes || '',
    [CodeCreateFields.ticketType]: values?.ticketType?.join(',') || '',
    [CodeCreateFields.description]: values?.description || '',
    [CodeCreateFields.managedByUsers]: values?.managedByUsers || [],
    [CodeCreateFields.sellerId]: values?.sellerId || '',
  };
};
export const toCreatingState = (body: codeGroupBody): CreatingGroupType => {
  return {
    clientName: body[CodeCreateFields.clientName],
    createdOn: new Date(),
    codesSent: 0,
    id: 'Creating',
    contactPerson: body[CodeCreateFields.contactPerson],
    totalCodes: 0,
    department: body[CodeCreateFields.department],
    status: 'Creating',
    ticketType: body[CodeCreateFields.ticketType],
    isCreated: false,
  };
};

export const toUpdateCodeData = (values: CodeCreateFormValues): codeGroupBody => {
  return removeEmptyKeys({
    [CodeCreateFields.brand]: values?.brand || '',
    [CodeCreateFields.clientName]: values?.clientName || '',
    [CodeCreateFields.contactPerson]: values?.contactPerson || '',
    [CodeCreateFields.clientEmail]: values?.clientEmailList || '',
    [CodeCreateFields.groupType]: values?.groupType || '',
    [CodeCreateFields.department]: values?.department || '',
    [CodeCreateFields.promoCodes]: values?.promoCodes || '',
    [CodeCreateFields.ticketType]: values?.ticketType?.join(',') || '',
    [CodeCreateFields.description]: values?.description || '',
    [CodeCreateFields.managedByUsers]: values?.managedByUsers || undefined,
    [CodeCreateFields.sellerId]: values?.sellerId || '',
  }) as codeGroupBody;
};

export const groupsToTableState = (
  values: getListCodeGroupsRes,
  creatingGroup: CreatingGroupType,
): DashboardTableDataT[] => {
  const cg =
    creatingGroup && !creatingGroup?.isCreated
      ? [
          {
            key: 0.1,
            id: 'creating',
            clientName: {id: 'creating', name: creatingGroup?.clientName},
            ticketType: creatingGroup?.ticketType,
            department: creatingGroup?.department,
            contactPerson: creatingGroup?.contactPerson,
            createdOn: creatingGroup?.createdOn?.toDateString(),
            totalCodes: 0,
            codesSent: 0,
            status: {isCreating: true},
            actions: {id: 'creating', isCreating: true},
          },
        ]
      : [];

  return [
    ...cg,
    ...values.map((el) => ({
      key: el?.id,
      id: String(el?.id),
      clientName: {id: String(el?.id), name: el?.clientName},
      ticketType: el.ticketType,
      department: el.department,
      contactPerson: el.contactPerson,
      createdOn: el.createdAt,
      totalCodes: el.codesUploaded,
      codesSent: el.codesSent,
      status: {type: el?.outboundStatus, groupType: el?.groupType},
      actions: {id: String(el?.id)},
    })),
  ];
};

export const toInitialCodeGroupState = (code?: CodeGroupT): Partial<codeGroupBody> => {
  return {
    [CodeCreateFields.clientName]: code?.clientName,
    [CodeCreateFields.clientEmail]: code?.clientEmailList?.join(', ') || code?.clientEmail,
    [CodeCreateFields.contactPerson]: code?.contactPerson,
    [CodeCreateFields.department]: code?.department,
    [CodeCreateFields.ticketType]: code?.ticketType,
    [CodeCreateFields.groupType]: code?.groupType,
    [CodeCreateFields.description]: code?.description,
    [CodeCreateFields.managedByUsers]: code?.managedByUsers,
    [CodeCreateFields.sellerId]: code?.sellerId,
  };
};

export const promoCodesToTableState = (values: getListPromoCodesRes): {table: GroupDetailTableDataT[]; used: any} => {
  const table = values.map((el) => ({
    key: el.id,
    id: String(el.id),
    code: {code: el.code, addRow: false, id: String(el.id)},
    guestName: {
      guestName: el.guestName,
      addRow: false,
      id: String(el.id),
      email: el?.email,
      codeStatus: el.outboundStatus as CodeSendStatuses,
    },
    email: {email: el?.email, edit: true, id: String(el.id)},
    actions: {edit: false, email: el?.email, codeStatus: el.outboundStatus as CodeSendStatuses, id: String(el.id)},
  }));
  return {table, used: undefined};
};

export const toSelectOptions = (values: any[]): SelectOptionT[] => {
  return values.map((el) => ({label: el?.name, value: el?.name}));
};

export const validateEmail = (email?: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const emailStateToArray = (emails: emailState) => {
  const rows: {id?: string; email?: string; name?: string}[] = Object.keys(emails).map((el) => ({
    id: el,
    email: emails?.[el].email,
    name: emails?.[el]?.name,
  }));
  return rows.filter((el) => validateEmail(el.email));
};

export const toSellersOptions = (values?: SellerT[]): SelectOptionT[] => {
  return values?.map((el) => ({label: `${el?.city}, ${el?.venueName}`, value: el?.id})) || [];
};

export const getGroupLocName = (sellers?: SellerT[], group?: CodeGroupT) => {
  const current = sellers?.find((el) => el?.id === group?.sellerId);
  return `${current?.city}, ${current?.venueName}`;
};

export const parseError = (e: unknown) => {
  const error = JSON.parse(JSON.stringify(e));
  const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
  return errorMessage || '';
};

const getStatusFromCodes = (codes?: PromoCodeT[], codesIds?: number[]) => {
  if (!codesIds?.length) return CodeSendStatuses.notsent;
  let status = CodeSendStatuses.sent;
  codesIds?.forEach((id) => {
    if (codes?.find((code) => String(code?.id) === String(id))?.outboundStatus === CodeSendStatuses.notsent)
      status = CodeSendStatuses.notsent;
  });
  return status;
};

export const groupByGuestTableState = (
  groupGuests: CodeGroupGuestT[],
  codes?: PromoCodeT[],
): {table: GroupDetailTableDataT[]; used: UsedCodesT} => {
  const unusedCodes: codeItemT[] = codes?.filter((el) => !el?.email)?.map((el) => ({id: el?.id, code: el?.code})) || [];
  const withEmails = groupGuests
    ?.filter((el) => el?.guestEmail)
    ?.sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt));

  const addRowIndex = -1;
  const addRow = {
    key: addRowIndex,
    id: String(addRowIndex),
    guestName: {addRow: true, id: String(addRowIndex)},
    email: {addRow: true, id: String(addRowIndex)},
    code: {
      addRow: true,
      id: String(addRowIndex),
      unused: unusedCodes,
    },
    actions: {addRow: true, id: String(addRowIndex), unused: unusedCodes},
  };

  const tableData = withEmails.map((el) => {
    const id = String(el?.id);
    const used = el?.promoCodeIds?.map((el) => {
      const codeValue = codes?.find((code) => el === code?.id);
      return {id: codeValue?.id, code: codeValue?.code, status: codeValue?.outboundStatus};
    });
    return {
      key: el?.id,
      id: String(id),
      guestName: {guestName: el.guestName, addRow: false, id: id, email: el?.guestEmail},
      email: {email: el?.guestEmail, edit: true, id: id},
      code: {
        id: id,
        unused: unusedCodes,
        used,
      },
      actions: {
        edit: false,
        email: el?.guestEmail,
        codeStatus: getStatusFromCodes(codes, el?.promoCodeIds),
        id: id,
        unused: unusedCodes,
        used,
      },
    };
  });
  const used = tableData.map((el) => ({
    id: el?.id,
    used: el?.code.used,
  }));
  return {table: [addRow, ...tableData], used};
};

export const MAX_CODES_PER_GUEST = 50;
